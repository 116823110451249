export const ButtonSubmit = () => import('../../components/button/Submit.vue' /* webpackChunkName: "components/button-submit" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderPage = () => import('../../components/layouts/HeaderPage.vue' /* webpackChunkName: "components/layouts-header-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSidebar = () => import('../../components/layouts/Sidebar.vue' /* webpackChunkName: "components/layouts-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserFooter = () => import('../../components/layouts/UserFooter.vue' /* webpackChunkName: "components/layouts-user-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserHeader = () => import('../../components/layouts/UserHeader.vue' /* webpackChunkName: "components/layouts-user-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserOffcanvasMenu = () => import('../../components/layouts/UserOffcanvasMenu.vue' /* webpackChunkName: "components/layouts-user-offcanvas-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMenuItem = () => import('../../components/molecules/MenuItem.vue' /* webpackChunkName: "components/molecules-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSvgIcon = () => import('../../components/molecules/SvgIcon.vue' /* webpackChunkName: "components/molecules-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContactHere = () => import('../../components/organisms/ContactHere.vue' /* webpackChunkName: "components/organisms-contact-here" */).then(c => wrapFunctional(c.default || c))
export const OrganismsModelList = () => import('../../components/organisms/ModelList.vue' /* webpackChunkName: "components/organisms-model-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsQuickLinks = () => import('../../components/organisms/QuickLinks.vue' /* webpackChunkName: "components/organisms-quick-links" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSubMenu = () => import('../../components/organisms/SubMenu.vue' /* webpackChunkName: "components/organisms-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeManufacturer = () => import('../../components/organisms/home/Manufacturer.vue' /* webpackChunkName: "components/organisms-home-manufacturer" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartAdminChartRanking = () => import('../../components/template/chart/AdminChartRanking.vue' /* webpackChunkName: "components/template-chart-admin-chart-ranking" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartColumnChartLeft = () => import('../../components/template/chart/ColumnChartLeft.vue' /* webpackChunkName: "components/template-chart-column-chart-left" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartColumnChartRight = () => import('../../components/template/chart/ColumnChartRight.vue' /* webpackChunkName: "components/template-chart-column-chart-right" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartSanitizeLineChart = () => import('../../components/template/chart/SanitizeLineChart.vue' /* webpackChunkName: "components/template-chart-sanitize-line-chart" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartUserChartRanking = () => import('../../components/template/chart/UserChartRanking.vue' /* webpackChunkName: "components/template-chart-user-chart-ranking" */).then(c => wrapFunctional(c.default || c))
export const TemplateChartWashLineChart = () => import('../../components/template/chart/WashLineChart.vue' /* webpackChunkName: "components/template-chart-wash-line-chart" */).then(c => wrapFunctional(c.default || c))
export const TemplateDepartmentForm = () => import('../../components/template/department/DepartmentForm.vue' /* webpackChunkName: "components/template-department-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateMeasurementDataForm = () => import('../../components/template/measurement/MeasurementDataForm.vue' /* webpackChunkName: "components/template-measurement-data-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateMeasurementForm = () => import('../../components/template/measurement/MeasurementForm.vue' /* webpackChunkName: "components/template-measurement-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateMeasurementScoreChart = () => import('../../components/template/measurement/MeasurementScoreChart.vue' /* webpackChunkName: "components/template-measurement-score-chart" */).then(c => wrapFunctional(c.default || c))
export const TemplateNotificationForm = () => import('../../components/template/notification/NotificationForm.vue' /* webpackChunkName: "components/template-notification-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateTagForm = () => import('../../components/template/tag/TagForm.vue' /* webpackChunkName: "components/template-tag-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateUserForm = () => import('../../components/template/user/UserForm.vue' /* webpackChunkName: "components/template-user-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
