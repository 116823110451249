import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _56aa843f = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7196beb8 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _525f8e70 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5ed1fd48 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _02f5b6b2 = () => interopDefault(import('../pages/regist.vue' /* webpackChunkName: "pages/regist" */))
const _615e69d8 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _beebf3ce = () => interopDefault(import('../pages/register-score.vue' /* webpackChunkName: "pages/register-score" */))
const _3aeb57ec = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _c3159ae2 = () => interopDefault(import('../pages/test-detect-hand.vue' /* webpackChunkName: "pages/test-detect-hand" */))
const _0d275b82 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _2cfca91c = () => interopDefault(import('../pages/measurement/user/index.vue' /* webpackChunkName: "pages/measurement/user/index" */))
const _0b69e3a5 = () => interopDefault(import('../pages/setting/department/index.vue' /* webpackChunkName: "pages/setting/department/index" */))
const _e35e1506 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _f864a566 = () => interopDefault(import('../pages/user/new.vue' /* webpackChunkName: "pages/user/new" */))
const _5aa73f9a = () => interopDefault(import('../pages/setting/department/new.vue' /* webpackChunkName: "pages/setting/department/new" */))
const _8943efea = () => interopDefault(import('../pages/setting/tag/new.vue' /* webpackChunkName: "pages/setting/tag/new" */))
const _c7f03b4c = () => interopDefault(import('../pages/measurement/user/_id.vue' /* webpackChunkName: "pages/measurement/user/_id" */))
const _8212e966 = () => interopDefault(import('../pages/setting/department/_id.vue' /* webpackChunkName: "pages/setting/department/_id" */))
const _b0af99b6 = () => interopDefault(import('../pages/setting/tag/_id.vue' /* webpackChunkName: "pages/setting/tag/_id" */))
const _7017d867 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _0d2895b1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _56aa843f,
    name: "contact"
  }, {
    path: "/forgot-password",
    component: _7196beb8,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _525f8e70,
    name: "login"
  }, {
    path: "/profile",
    component: _5ed1fd48,
    name: "profile"
  }, {
    path: "/regist",
    component: _02f5b6b2,
    name: "regist"
  }, {
    path: "/register",
    component: _615e69d8,
    name: "register"
  }, {
    path: "/register-score",
    component: _beebf3ce,
    name: "register-score"
  }, {
    path: "/reset-password",
    component: _3aeb57ec,
    name: "reset-password"
  }, {
    path: "/test-detect-hand",
    component: _c3159ae2,
    name: "test-detect-hand"
  }, {
    path: "/user",
    component: _0d275b82,
    name: "user"
  }, {
    path: "/measurement/user",
    component: _2cfca91c,
    name: "measurement-user"
  }, {
    path: "/setting/department",
    component: _0b69e3a5,
    name: "setting-department"
  }, {
    path: "/setting/tag",
    component: _e35e1506,
    name: "setting-tag"
  }, {
    path: "/user/new",
    component: _f864a566,
    name: "user-new"
  }, {
    path: "/setting/department/new",
    component: _5aa73f9a,
    name: "setting-department-new"
  }, {
    path: "/setting/tag/new",
    component: _8943efea,
    name: "setting-tag-new"
  }, {
    path: "/measurement/user/:id",
    component: _c7f03b4c,
    name: "measurement-user-id"
  }, {
    path: "/setting/department/:id",
    component: _8212e966,
    name: "setting-department-id"
  }, {
    path: "/setting/tag/:id",
    component: _b0af99b6,
    name: "setting-tag-id"
  }, {
    path: "/user/:id",
    component: _7017d867,
    name: "user-id"
  }, {
    path: "/",
    component: _0d2895b1,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
